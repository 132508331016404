import css from "@styled-system/css"
import React from "react"
import styled from "styled-components"
import { HideIcon } from "./hide-icon"
import { ShowIcon } from "./show-icon"

export type TogglePasswordProps = {
  type: string
  setType(type: string): void
  className?: string
}

export const TogglePassword = styled(
  ({ type, setType, className }: TogglePasswordProps) => {
    return (
      <span
        aria-hidden
        className={className}
        onClick={() => {
          if (type === "password") {
            setType("text")
          } else {
            setType("password")
          }
        }}
      >
        {type === "password" ? (
          <>
            <ShowIcon />
            <span>Show</span>
          </>
        ) : (
          <>
            <HideIcon />
            <span>Hide</span>
          </>
        )}
      </span>
    )
  }
)`
  ${css({
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    svg: {
      mr: 2,
    },
    span: {
      fontWeight: "bold",
      color: "gray.6",
    },
  })}
`

TogglePassword.displayName = "TogglePassword"
