import css from "@styled-system/css"
import React, { useState } from "react"
import styled from "styled-components"
import { variant } from "styled-system"
import { TogglePassword } from "./toggle-password"

export type InputProps = JSX.IntrinsicElements["input"] & {
  prefix?: string
  suffix?: string
  error?: boolean
  id: string
}

const disabledVariant = variant({
  prop: "disabled",
  variants: {
    true: {
      cursor: "not-allowed",
      "&:hover": {
        boxShadow: "none",
      },
      input: {
        cursor: "not-allowed",
      },
    },
  },
})

const errorVariant = variant({
  prop: "error",
  variants: {
    true: {
      borderColor: "error.700",
      ":hover": {
        borderColor: "error.700",
      },
      ":focus-within": {
        borderColor: "error.700",
        boxShadow: "0px 0px 0px 3px rgba(178, 13, 35, 0.15)",
      },
    },
  },
})

const InputPrefix = styled.span`
  ${css({
    fontWeight: "bold",
    color: "gray.6",
  })}
`

InputPrefix.displayName = "InputPrefix"

const InputSuffix = styled.span`
  ${css({
    fontWeight: "bold",
    color: "gray.6",
  })}
`

InputSuffix.displayName = "InputSuffix"

const UnstyledInput = React.forwardRef(
  ({ prefix, suffix, error, className, type: givenType, ...inputProps }: InputProps, ref) => {
    const [type, setType] = useState(givenType || "text")
    return (
      <div className={className}>
        {prefix && <InputPrefix aria-hidden>{prefix}</InputPrefix>}
        <input {...inputProps} ref={ref as any} type={type} size={1} />
        {givenType === "password" && <TogglePassword type={type} setType={setType} />}
        {suffix && <InputSuffix aria-hidden>{suffix}</InputSuffix>}
      </div>
    )
  }
)
UnstyledInput.displayName = "UnstyledInput"

export const Input = styled(UnstyledInput)`
  display: flex;
  align-items: center;
  ${css({
    bg: "gray.10",
    // bg: "white",
    paddingX: 4,
    borderWidth: 1,
    borderRadius: "small",
    borderStyle: "solid",
    borderColor: "gray.400",
    input: {
      /**
       * The `input` is not actually `1rem` wide, it fills the
       * space because of `flex-grow`. This is required for the
       * input to shrink to the appropriate size in Chrome.
       */
      width: "1rem",
      background: "inherit",
      flexGrow: 1,
      border: "none",
      outline: "none",
      fontSize: 1,
      paddingY: "13px",
      "::placeholder": {
        color: "gray.400",
      },
      ":disabled": {
        background: "inherit",
      },
    },
    ":hover": {
      borderColor: "gray.600",
    },
    ":focus-within": {
      outline: "none",
      boxShadow: "0px 0px 0px 3px rgba(0, 0, 0, 0.15);",
      borderColor: "gray.600",
    },
    ":disabled": {
      borderColor: "gray.400",
    },
  })}
  ${InputPrefix} {
    ${css({ paddingY: "13px", marginRight: 4 })}
  }
  ${InputSuffix} {
    ${css({ paddingY: "13px", marginLeft: 4 })}
  }
  input {
    appearance: none;
  }
  /* Hide Arrows for Numbers */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* Disable chrome autocomplete background color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  ${disabledVariant}
  ${errorVariant}
`

Input.displayName = "Input"
