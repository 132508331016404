import React, { ReactNode } from "react"
import css from "@styled-system/css"
import styled from "styled-components"

export type FieldProps = {
  name: string
  label?: string
  description?: string
  help?: string
  required?: boolean
  optional?: boolean
  error?: ReactNode
  action?: ReactNode
  htmlFor: string
  children: ReactNode | ((a11yProps: any) => JSX.Element)
}

/**
 * Adds the label and other metadata to an input.
 *
 * Example:
 *
 *     <Field
 *       label="Username"
 *       description="The name you'll use to log in"
 *       help="Minimum 5 characters"
 *       required
 *      >
 *       <input name="username" />
 *     </Field>
 */
export const Field = styled(
  ({ name, label, description, help, error, required, optional, action, children, htmlFor, ...rest }: FieldProps) => {
    const ids = {
      label: `${name}-label`,
      description: `${name}-description`,
      help: `${name}-help`,
      error: `${name}-error`,
    }
    const a11yProps: any = {
      name,
      "aria-label": label,
      "aria-describedby": "",
    }

    if (required) {
      a11yProps["aria-required"] = true
    }

    if (description) {
      a11yProps["aria-describedby"] += " " + ids.description
    }

    if (help) {
      a11yProps["aria-describedby"] += " " + ids.help
    }

    if (error) {
      a11yProps["aria-invalid"] = true
      a11yProps["aria-errormessage"] += ids.error
    }

    return (
      <FieldLabel {...rest} htmlFor={htmlFor}>
        <FieldLabelContainer>
          <FieldLabelText id={ids.label} aria-hidden>
            {label}
            {required && <span aria-hidden> *</span>}
            {optional && <span aria-hidden> (optional)</span>}
          </FieldLabelText>
          {action && <div>{action}</div>}
        </FieldLabelContainer>
        {description && (
          <FieldInfo id={ids.description} aria-hidden>
            {description}
          </FieldInfo>
        )}
        {typeof children === "function" ? children(a11yProps) : children}
        {!error && help && (
          <FieldInfo id={ids.help} aria-hidden>
            {help}
          </FieldInfo>
        )}
        {error && (
          <FieldError id={ids.error} aria-live="polite">
            {error}
          </FieldError>
        )}
      </FieldLabel>
    )
  }
)`
  > * + * {
    ${css({ mt: 2 })}
  }
`

Field.displayName = "Field"

const FieldLabelContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const FieldLabel = styled.label`
  width: 100%;
  display: block;
`

FieldLabel.displayName = "FieldLabel"

const FieldLabelText = styled.div`
  ${css({
    fontWeight: "bold",
    color: "gray.6",
  })}
`

FieldLabelText.displayName = "FieldLabelText"

const FieldInfo = styled.div`
  ${css({
    color: "gray.5",
    fontSize: 1,
  })}
`

FieldInfo.displayName = "FieldInfo"

const FieldError = styled.div`
  ${css({
    color: "error.700",
    a: {
      textDecoration: "none",
      fontWeight: "bold",
      color: "primary.600",
    },
    svg: {
      mr: 2,
    },
  })}
`

FieldError.displayName = "FieldError"
