import React from "react"
import { Field } from "@ui/field"
import { Input } from "./input"
import { FieldRenderProps } from "react-final-form"

export type InputFieldProps = FieldRenderProps<string, HTMLInputElement>

export const InputField = React.forwardRef(
  ({ input, meta, label, description, help, required, optional, disabled, ...extra }: InputFieldProps, ref) => {
    const fillError = meta.modified && meta.error
    const submitError = meta.submitError
    const error = submitError || fillError
    return (
      <Field
        htmlFor={input.id}
        name={input.name}
        label={label}
        description={description}
        help={help}
        required={required}
        optional={optional}
        error={error}
      >
        {(a11yProps) => (
          <Input {...input} {...a11yProps} ref={ref} error={error} disabled={disabled} required={required} {...extra} />
        )}
      </Field>
    )
  }
)

InputField.displayName = "InputField"
